import * as React from "react";
import image from "../images/IMG_20220614_052655C.jpg";
import qurbandhVideo from "../images/qurbandh-video.mp4";
import qurbanCertificate from "../images/qurban-certificate.svg";
import digitalSignage from "../images/digital-signage.svg";
import formAssets from "../images/form-assets.svg";
import hanifanazkaProfilephoto from "../images/hanifanazka-profilephoto.jpg";
import mohnAkbariProfilephoto from "../images/mohn.akbari-profilephoto.jpg";
import Container from "../components/Container";
import Prose from "../components/Prose";

function QurbanDHPage() {
  return (
    <>
      <Container>
        <Prose>
          <h1>QurbanDH</h1>
          <p>
            QurbanDH is a web application that serve qurban registration,
            receipt and certificate builder, and digital signage. It's an online
            form that we built to migrate from years of manual paper form.
          </p>
          <p>
            Our goal was to develop a web app that would automate qurban
            registration and provide transparency on the process.
          </p>
        </Prose>
        <p className="font-['EB_Garamond'] text-3xl text-amber-800 text-opacity-75 my-12">
          We helped Darul Hijrah qurban committee automate qurban registration
          and provide transparency in the process.
        </p>
        <img className="my-12 w-full" src={image} />
        <Prose>
          <h3>Our Approach</h3>
          <p>
            Darul Hijrah's qurban committee has years of experience managing
            qurban, and we want to make the transition to online as easy as
            possible.
          </p>
        </Prose>
        <video
          className="my-12 w-full"
          src={qurbandhVideo}
          autoPlay
          muted
          loop
        />
        <Prose>
          <h3>Experience</h3>
          <p>
            We wanted to keep the amount of technical knowledge required to a
            bare minimum. As a result, we made the decision to use Google Sheets
            as a database.
          </p>
          <h3>Feature</h3>
          <p>
            Our goal was to digitize the entire process. Registration from paper
            to online, automate receipt and certificate building, and paper
            based announcement to digital signage.
          </p>
        </Prose>
      </Container>
      <Container className="my-12">
        <div className="grid grid-cols-2 gap-6">
          <img className="w-full col-span-2" src={formAssets} />
          <img className="w-full" src={qurbanCertificate} />
          <img className="w-full" src={digitalSignage} />
        </div>
      </Container>
      <Container>
        <Prose>
          <h3>People Involved</h3>
          <p className="not-prose">
            <ul className="space-y-2">
              <li className="flex items-center gap-4">
                <img
                  className="h-12 rounded-full"
                  src={hanifanazkaProfilephoto}
                />
                <div>Hanifan Azka</div>
              </li>
              <li className="flex items-center gap-4">
                <img
                  className="h-12 rounded-full"
                  src={mohnAkbariProfilephoto}
                />
                <div>Akbari</div>
              </li>
            </ul>
          </p>
          <h3>Technical Achievement</h3>
          <p>
            We are successfuly integrate Google Sheets as a database, AWS Simple
            Email Service to send receipt and notification, reCAPTCHA, and do
            serverless deployment with Fly.
          </p>
        </Prose>
      </Container>
      <div className="bg-neutral-800 my-12 py-12 mb-0 hidden">
        <div className="max-w-screen-lg px-4 mx-auto">
          <Prose className="prose-invert">
            <h3>Technical Achievement</h3>
            <p>
              We are successfuly integrate Google Sheets as a database, AWS
              Simple Email Service to send receipt and notification, and do
              serverless deployment with Fly.
            </p>
          </Prose>
        </div>
      </div>
    </>
  );
}

export default QurbanDHPage;
